@if (showInstallButton) {
<div class="install-app">
  <div class="container-install">
    <img
      src="./assets/icons/icon-72x72.png"
      alt="Logo de l'application gomoto"
    />
    <p class="bold">gomoto</p>
    <h2>INSTALLE L'app sur ton tel !</h2>
    <p class="speech bold">
      Installe l'application GoMoto directement sur ton téléphone et garde là au
      chaud à porté de main 😉 Une balade est si vite arrivée...
    </p>
    <app-button-component
      [isEnable]="true"
      buttonStyle="black"
      (click)="installPWA()"
      >GOOOOOOOOO!</app-button-component
    >
    <app-button-component [isEnable]="true" (click)="cancelInstall()"
      >PLUS TARD...</app-button-component
    >
  </div>
</div>
}

<router-outlet></router-outlet>

@if (message && status) {
<app-toast [message]="message" [status]="status"></app-toast>
}
