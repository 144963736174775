@if (message) {
<div class="container-toast">
  <div [ngClass]="status" class="toast-layout-animation"></div>
  <div [ngClass]="status" class="toast-layout">
    @if (status === 'warning') {
    <img src="/assets/warning.svg" alt="Erreur" />
    } @if (status === 'error') {
    <img src="/assets/red-cross.svg" alt="Succes" />
    } @if (status === 'success') {
    <img src="/assets/correct.svg" alt="Attention" />
    }
    <p>{{ message }}</p>
  </div>
</div>
}
