import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Status } from 'src/app/_enum/status';
import { ToastService } from 'src/app/_services/toast.service';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class ToastComponent implements OnChanges {
  @Input() status: Status;
  @Input() message: string;

  constructor(private toastService: ToastService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message'] || changes['status']) {
      setTimeout(() => {
        this.toastService.updateToast(null, null);
      }, 5000);
    }
  }
}
